const endpoints = {
  STATUS: "status/",
  auth: {
    LOGIN: "auth/login/",
    LOGOUT: "auth/logout/",
    LOG_OUT_ALL: "auth/logoutall/",
    USER: "auth/user/",
    GET_TOKEN_SPY: "auth/delegate/login/",
    TERMS: "auth/terms-and-conditions/",
    REFRESH_USER_ACCESS_TOKEN: "auth/refresh-user-tokens/",
  },
  FIRST_STEP: "dashboard/first-step/",
  FIRST_STEP_UPDATE: "dashboard/first-step/update/",
  RANKS_LIST: "rank/list/",
  dashboard: {
    BEST_PERFORMERS: "dashboard/team/best-performers/",
    LATEST_RANK: "dashboard/team/latest-rank-upgrades/",
    SUGGESTIONS: "dashboard/team/suggestions/",
    TEAM_HISTORY: "dashboard/team/history/",
    RANK_REPORT: "dashboard/team/report/",
    STATISTICS: (filter) => `dashboard/team/stats/?filter=${filter}`,
    LATEST_USERS: "dashboard/users/latest/",
    REWARDS_POINTS: (id, type) => `rewards/${id}/points?type=${type}`,
    TEAM_DEPTH: (id) => `dashboard/users/${id}/depth/`,
  },
  referrals: {
    PAST_REFERRALS: "dashboard/referrals/past/",
    REAL_TIME_USERS: "dashboard/referrals/analytics",
  },
  community: {
    FIRST_lINE: (id, page, search = "") =>
      `dashboard/community/users/${id}/first-line/?page=${page}&limit=100${
        search ? "&search=" + search : ""
      }`,
    COMMUNITY_STATS: "dashboard/community/stats/",
    COMMUNITY_RANKS: "dashboard/community/ranks/",
    COMMUNITY_GROWTH_POINTS_ANALYTICS:
      "dashboard/community/growth/points/analytics",
    COMMUNITY_GROWTH_RANKS_ANALYTICS:
      "dashboard/community/growth/ranks/analytics",
    COMMUNITY_GROWTH_COUNTRY_ANALYTICS: "dashboard/team/users-count-by-country",
    COMMUNITY_WEAK_LEG_REWARDS: (id) =>
      `dashboard/community/weak-leg/user-detail/${id}/points/`,
    COMMUNITY_USER_DETAILS: `/dashboard/community/weak-leg/user-details/`,
    COMMUNITY_STRONG_LEG: "dashboard/community/strong-leg/",
  },
  rankOverView: {
    RANK: "rank/",
    RANK_ANALYTICS: "rank/analytics",
    RANK_REPORT: "rank/report/",
    REWORDS: "rewards/products/",
    MONTHLY_COMMISSIONABLE_RANK: "rank/commissionable-ranks/monthly",
  },
  rankBonus: {
    BONUS_HISTORY: "rewards/turnover-bonus/history/",
    TURNOVER_BONUS: "rewards/turnover-bonus/list/",
  },
  builderReport: {
    USERS: "dashboard/users/",
  },
  directBonus: {
    REPORT: "rewards/direct-bonus/report/",
    ANALYTICS: "rewards/direct-bonus/analytics/",
    HISTORY: "rewards/direct-bonus/history/",
    FIRST_LINE_USERS: "rewards/direct-bonus/first-line-users/",
  },
  infinityBonus: {
    REPORT: "rewards/infinity-bonus/report/",
    ANALYTICS_CHART: "rewards/infinity-bonus/analytics-chart/",
    RANK_ANALYTICS_CHART: "rewards/infinity-bonus/rank-analytics-chart/",
    BONUS_HISTORY: "rewards/infinity-bonus/history/",
  },
  houseBonus: {
    REPORT: "rewards/house-bonus/report/",
    DETAILS: "rewards/house-bonus-info/",
  },
  wallets: {
    COMMISSION_REPORT: "wallets/commission/report/",
    DEBT_WALLETS: "wallets/debts/",
    TRANSACTIONS: "wallets/transactions/",
    WITHDRAW: "wallets/withdraw/",
    WITHDRAW_CONFIRMATION: "wallets/withdraw/confirm/",
    COMMISSION_STATISTICS: "wallets/commission/stats/",
    DEBTS_CONFIG: "wallets/debts/user-config/",
    DEBTS_HALT_REQUEST: "wallets/debts/halt/request/",
    DEBTS_HALT_CANCEL: "wallets/debts/halt/cancel-request/",
    DEBTS_HALT_RE_ENABLE: "wallets/debts/re-enable/",
  },
  exports: {
    TRANSACTION_HISTORY: "exports/transaction-history/",
    DIRECT_BONUS_HISTORY: "exports/direct-bonus-history/",
    REWARDS_HISTORY: "exports/rewards-history/",
    EXPORT_FILTERS: "exports/export-filters/",
    EXPORT_LIST: "exports/",
    INFINITY_BONUS_HISTORY: "exports/infinity-bonus-history/",
    UNILEVEL_BONUS_HISTORY: "exports/unilevel-bonus-history/",
    TURNOVER_BONUS_HISTORY: "exports/turnover-bonus-history/",
  },
  rewardsHistory: {
    FILTERS: "rewards/history/@filters",
    REWARDS_HISTORY: "rewards/history/",
  },
  activeQualified: {
    ACTIVATION: (id) => `rewards/activations/${id}`,
    UNILEVEL_BONUS_LINES: "rewards/unilevel-bonus/lines/",
  },
  activeQualifiedInfinityBonus: {
    REPORT: "rewards/infinity-bonus/analytics/",
    RANK: "rewards/infinity-bonus/commissionable-ranks/",
  },
  activeQualifiedUniLevelBonus: {
    ANALYTICS: "rewards/unilevel-bonus/analytics/",
    UNILEVEL_BONUS_OVERTIME_ANALYTICS:
      "rewards/unilevel-bonus/overtime/analytics",
  },
  events: {
    INTERNATIONAL: "events/upcoming/?region=INTERNATIONAL",
    NATIONAL: "events/upcoming/?region=NATIONAL",
    INFO: "events/:id/info/",
    TIMELINE: "events/:id/timelines/",
    ENROLL: "events/:id/enroll/",
  },
  fastStart: {
    ANALYTICS: "rewards/fast-start/analytics/",
  },
  unilevelBonus: {
    REPORT: "rewards/unilevel-bonus/report/",
    ANALYTICS: "rewards/unilevel-bonus/analytics-chart/",
    HISTORY: "rewards/unilevel-bonus/history/",
  },
  vipSupport: {
    ELIGIBILITY: "vip-support/eligibility/",
    SUBMISSIONS: "vip-support/form-submissions/",
    READ_VIP_POPUP: "auth/user/show-vip-support-pop-up/",
  },
  wiki: {
    WIKI: "wiki/url/",
  },
  pools: {
    POOLS: "pools/stats/",
    DATE_STATS: "pools/date-stats/",
    // PAYMENTS: "pools/payments/",
  },
  notifications: {
    GET_NOTIFICATIONS: "notifications/",
    GET_BROADCAST_HISTORY: "notifications/all",
    READ_BROADCAST_NOTIFICATION: (id) =>  `notifications/broadcast/${id}/read/`,
    GET_TAG_LIST: "notifications/tags-list/",
  },


  PLATFORM_CONFIG: "platform-config/",
};

export default endpoints;
