import React from "react";

const Notifications = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0358 19.1663C13.3601 19.7627 12.4725 20.1246 11.5003 20.1246C10.5282 20.1246 9.64057 19.7627 8.96482 19.1663M17.2478 12.6313V9.58301C17.2478 6.39915 14.6842 3.83301 11.5003 3.83301C8.31647 3.83301 5.71809 6.28975 5.71809 9.58301V12.6106C5.71809 13.0737 5.64592 13.5337 5.50436 13.9729L4.79667 16.169C4.77729 16.2291 4.82056 16.2912 4.88184 16.2912H18.0776C18.1423 16.2912 18.1883 16.2281 18.1684 16.1664L17.4559 13.9555C17.3181 13.5276 17.2478 13.0808 17.2478 12.6313Z"
        stroke="#07F9C8"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Notifications;
