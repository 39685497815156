/**
 * Function return all query params as an object
 */

import config from "../data/config";
import { toPng } from "html-to-image";

function getUrlParams() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paramsObject = {};

  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }

  return paramsObject;
}

/**
 * @param {Object} navigate - navigate hook
 * @param {String} path - the path you want to visit
 * @param {String} redirect - the redirect URL
 */
function navigateWithRedirect(navigate, path, redirect = window.location.href) {
  redirect = encodeURIComponent(redirect);
  navigate(path + "?" + config.REDIRECT_URL_LABEL + "=" + redirect);
}

function getNextAndPrevInArray(arr, value) {
  const index = arr.indexOf(value);
  if (index === -1) {
    return [null, null]; // Value not found in the array.
  }

  const nextValue = index + 1 < arr.length ? arr[index + 1] : null;
  const prevValue = index - 1 >= 0 ? arr[index - 1] : null;
  return [nextValue, prevValue];
}

function calculatePercentageChange(oldValue, newValue) {
  if (parseFloat(oldValue) === 0 && parseFloat(newValue) === 0) {
    return 0;
  } else if (parseFloat(oldValue) === 0 && parseFloat(newValue) > 0) {
    return 100;
  } else {
    const difference = newValue - oldValue;
    const percentageChange = (difference / oldValue) * 100;
    return percentageChange;
  }
}
function calculatePercentageBetweenCurrentAndMaxValue(current, max) {
  if (!max) {
    max = 0;
  }
  if (parseFloat(max) === 0) return 100;
  if ((current / max) * 100 > 100) return 100;
  return (current / max) * 100;
}

// This is the function we wrote earlier
async function copyTextToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

function getOrCreateTooltip(chart) {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.borderRadius = "30px";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.background = "rgba(97, 92, 92, 0.8)";
    tooltipEl.style.minWidth = "200px";

    const table = document.createElement("table");
    chart.canvas.parentNode.style.position = "relative";
    table.style.margin = "0px";
    table.style.padding = "10px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
}

const truncateNumber = (number, digits = 4) => {
  const factor = Math.pow(10, digits);
  return (Math.floor(number * factor) / factor).toFixed(digits);
};

const downloadImage = (divRef, filename) => {
  toPng(divRef.current).then((dataUrl) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataUrl;
    link.click();
  });
};

//  functions that remove everything after "__" inside a string
function removeAfterDoubleUnderscore(string) {
  let tmpString = string;
  const index = tmpString.indexOf("__");
  if (index > -1) {
    tmpString = tmpString.substring(0, index);
  }
  return snakeToCapitalCase(tmpString);
}

// a function that convert a string from snake to capital case
function snakeToCapitalCase(string) {
  const words = string.split("_");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
}

function handleResetFilter(
  filterKey,
  initialFilters,
  currentFilter,
  setCurrentFilter,
  extraFilters,
  setExtraFilters
) {
  setCurrentFilter({
    ...currentFilter,
    [filterKey]: initialFilters[filterKey],
  });
  let tempObject = {
    ...extraFilters,
    [filterKey]: null,
  };
  if (extraFilters[filterKey + "__lte"] || extraFilters[filterKey + "__gte"]) {
    tempObject = {
      ...extraFilters,
      [filterKey + "__lte"]: null,
      [filterKey + "__gte"]: null,
    };
  }
  setExtraFilters(tempObject);
}

function formatNumberEuropean(num, afterFloatingPoint = 6) {
  if (num || parseInt(num) === 0) {
    // Convert the number to a string and split it into integer and decimal parts
    const parts = (+num)?.toFixed(afterFloatingPoint)?.toString()?.split(".");

    // Remove trailing zeros from the decimal part
    parts[1] = parts[1]?.replace(/0+$/, "");

    // Add a period (.) as the thousands separator
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Join the parts with a comma (,) as the decimal separator, only if there are decimals
    const result = parts[1] ? parts?.join(",") : parts[0];
    return result;
  }
}

function addSlashIfNeeded(inputString) {
  // Check if the string already ends with a '/'
  if (!inputString?.endsWith("/")) {
    // If not, add a '/' to the end of the string
    return inputString + "/";
  }
  // If it already ends with '/', return the string as is
  return inputString;
}

const notificationTime = (time) => {
  const date = new Date(time);
  const now = new Date();
  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} days ago`;
  else if (hours > 0) return `${hours} hours ago`;
  else if (minutes > 0) return `${minutes} minutes ago`;
  else if (seconds > 0) return `${seconds} seconds ago`;
  else return "now";
}

function timeAgo(timestamp) {
  const currentDate = new Date();
  const lastPasswordChangeDate = new Date(timestamp);

  const timeDifference = currentDate - lastPasswordChangeDate;

  const minutesDifference = Math.floor(timeDifference / (1000 * 60));
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  let timeUnit;
  let unitLabel;

  if (minutesDifference < 60) {
    timeUnit = minutesDifference;
    unitLabel = " Minutes";
  } else if (hoursDifference < 24) {
    timeUnit = hoursDifference;
    unitLabel = " Hour";
  } else {
    timeUnit = daysDifference;
    unitLabel = " Day";
  }

  const pluralSuffix = timeUnit === 1 ? "" : "s";
  const passwordUnit = [timeUnit, `${unitLabel}${pluralSuffix} ago`];

  return passwordUnit;
}

export {
  getUrlParams,
  navigateWithRedirect,
  getNextAndPrevInArray,
  calculatePercentageChange,
  copyTextToClipboard,
  getOrCreateTooltip,
  truncateNumber,
  downloadImage,
  removeAfterDoubleUnderscore,
  handleResetFilter,
  calculatePercentageBetweenCurrentAndMaxValue,
  formatNumberEuropean,
  addSlashIfNeeded,
  notificationTime,
  timeAgo
};
