import React, { useRef, useContext, useState, useEffect } from "react";

// Components
import Loader from "./../../components/ui/loader/Loader";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Header from "../../components/ui/header/Header";
import StatusResponse from "../../components/advanced/statusResponse/StatusResponse";
import ErrorModal from "../../components/advanced/errorModal/ErrorModal";
import UnauthorizedModal from "../../components/advanced/unauthorizedModal/UnauthorizedModal";
import LogoutModal from "../../components/advanced/logoutModal/LogoutModal";
import {
  TermsModal,
  // InfoModalContent,
} from "../../components/advanced/termsModal/TermsModal";
import Notification from "../../components/advanced/notification/Notification";
import BroadcastBody from "../../components/advanced/broadcastBody/BroadcastBody";
import { VipSupportModal } from "../../components/advanced/vipSupportModal/VipSupportModal";

// Libraries
import { Outlet, useNavigate } from "react-router-dom";
import { Button, MagicModal } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import useQuery from "@hybris-software/use-query";
import useWebSocket from "react-use-websocket";

import { SDK2AccessWidget } from "@vai-software/2access-sdk-kit-front";

// Contexts
import { RoutesContext } from "../../contexts/RoutesContext";
import GeneralInfoContext from "../../contexts/GeneralInfoContext";
import NotificationContext from "../../contexts/NotificationContext";

// Data
import endpoints from "../../data/endpoints";
import config from "../../data/config";
import theme from "../../data/theme";
import texts from "../../data/texts";
import languages from "../../data/languages";

// Sounds
import NewNotification from "../../assets/sounds/new-notification.mp3";

// Icon
import BillIcon from "../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./MainLayout.module.css";

const MainLayout = () => {
  // Refs
  const generalModalRef = useRef(null);
  const logoutModalRef = useRef(null);
  const termsRef = useRef(null);
  const navigate = useNavigate();

  // Contexts
  const { sidebarRoutes } = useContext(RoutesContext);
  const [generalInfo, setGeneralInfo] = useContext(GeneralInfoContext);
  const { paths } = useContext(RoutesContext);
  const [termsPdf, setTermsPdf] = useState();
  const [linkPdf, setLinkPdf] = useState("");
  const [sdkKey, setSdkKey] = useState(new Date());

  // States
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [newNotification, setNewNotification] = useState([]);

  // WebSocket notification
  const { sendMessage } = useWebSocket(config.WEBSOCKET_NOTIFICATION_URL, {
    onOpen: () => {
      sendMessage(localStorage.getItem("token"));
    },
    onMessage: (event) => {
      const response = JSON.parse(event?.data);
      if (
        response?.event === "OLD_BROADCAST_NOTIFICATIONS" &&
        response?.data?.length > 0
      ) {
        setNotificationsList(response?.data);
      }
      if (
        response?.event === "BROADCAST_NOTIFICATION" &&
        response?.data?.length > 0
      ) {
        showNotification(<BroadcastBody broadcast={response?.data?.[0]} />);
        setNotificationsList((prev) => [...response?.data, ...prev]);
        const audio = new Audio(NewNotification);
        audio.play();
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  //Queries
  const userInfoAPI = useQuery({
    url: endpoints.auth.USER,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
    onSuccess: (response) => {
      setTermsPdf(response?.data?.tbeTermsAccepted);
    },
  });

  const rankListAPI = useQuery({
    url: endpoints.RANKS_LIST,
    method: "GET",
    executeImmediately: true,
    clientOptions: {
      timeout: 120000,
    },
  });

  const refreshUserAccessTokenAPI = useQuery({
    url: endpoints.auth?.REFRESH_USER_ACCESS_TOKEN,
    method: "POST",
    executeImmediately: false,
    onSuccess : (response) => {
      localStorage.setItem("user-access-token", response?.data?.userAccessToken);
      localStorage.setItem("user-access-token-refresh-token", response?.data?.userRefreshToken);
      setSdkKey(new Date())
    }
  });

  // Functions
  function showNotification(message) {
    const newNotification = {
      id: Date.now(), // unique identifier for each notification
      message,
    };

    setNewNotification((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);

    // Automatically close the notification after 3 seconds
    setTimeout(() => {
      closeNotification(newNotification.id);
    }, 5000);
  }

  function closeNotification(id) {
    setNewNotification((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }

  useEffect(() => {
    if (rankListAPI.isSuccess && userInfoAPI.isSuccess) {
      setGeneralInfo((prevDetails) => ({
        ...prevDetails,
        rankInfo: rankListAPI?.response?.data,
        userInfo: userInfoAPI?.response?.data,
        endpoints: endpoints,
        config: config,
        texts: texts,
        languages: languages,
        theme: theme,
      }));
    }
    // eslint-disable-next-line
  }, [rankListAPI.isSuccess, userInfoAPI.isSuccess]);

  // Functions
  function generalErrorsResolver(error) {
    switch (+error.response.status) {
      case 401:
        generalModalRef.current.updateBody(<UnauthorizedModal />, {
          showCloseIcon: false,
        });
        return;
      case 403:
        generalModalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title="Not Allowed"
            description={`You are not allowed to do this action.`}
          >
            <Button onClick={() => generalModalRef.current.destroy()}>
              Close
            </Button>
          </StatusResponse>
        );
        return;
      default:
        generalModalRef.current.updateBody(
          <ErrorModal error={error} modalRef={generalModalRef} />
        );
        return;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (termsPdf === false || termsPdf === null) {
        termsRef?.current?.updateBody(
          <TermsModal
            linkPdf={linkPdf}
            modalRef={termsRef}
            generalModalRef={generalModalRef}
          />
        );
      }
    }, 2000);
    //eslint-disable-next-line
  }, [termsPdf]);

  useEffect(() => {
    setTimeout(() => {
      if (userInfoAPI?.response?.data?.showVipSupportPopup) {
        generalModalRef?.current?.updateBody(
          <VipSupportModal generalModalRef={generalModalRef} />
        );
      }
    }, 0);
    //eslint-disable-next-line
  }, [userInfoAPI.isSuccess]);

  return (
    <PermissionRoute
      loader={<Loader fullscreen={true} />}
      minimumLoadingTime={0}
      firstLoadingApi={userInfoAPI.isLoading}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        setLinkPdf(response?.data?.termsAndCondition);
        return true;
      }}
    >
      {Object.keys(generalInfo).length !== 0 && (
        <NotificationContext.Provider
          value={[notificationsList, setNotificationsList]}
        >
          <MagicModal
            overlayStyle={{ background: "var(--overlay-modal)" }}
            ref={logoutModalRef}
            body={<LogoutModal modalRef={logoutModalRef} />}
            destroyBodyOnClose={false}
          />

          <MagicModal
            overlayStyle={{ background: "var(--overlay-modal)" }}
            closeIcon={null}
            ref={generalModalRef}
            contentStyle={{ padding: "0" }}
          />

          <MagicModal
            overlayStyle={{ background: "var(--overlay-modal)" }}
            ref={termsRef}
            body={
              <TermsModal
                linkPdf={linkPdf}
                modalRef={termsRef}
                generalModalRef={generalModalRef}
              />
            }
            closeIcon={null}
          />

          {sessionStorage.getItem("inspectorTool") === "true" && (
            <>
              <div className={Style.borderWarning} style={{ top: 0, right: 0 }}>
                <div className={Style.mode}>SPY MODE</div>
              </div>
              <div
                className={Style.borderWarning}
                style={{ bottom: 0, left: 0 }}
              >
                <div className={Style.modeBottom}>SPY MODE</div>
              </div>
              <div
                className={Style.borderWarnSide}
                style={{ top: 0, left: 0 }}
              />
              <div
                className={Style.borderWarnSide}
                style={{ top: 0, right: 0 }}
              />
            </>
          )}

          <div className={Style.layout}>
            <div className={Style.notificationContainer}>
              {newNotification?.map((notification) => (
                <Notification
                  key={notification.id}
                  id={notification.id}
                  message={notification.message}
                  onClose={closeNotification}
                />
              ))}
            </div>

            <SDK2AccessWidget
              key={sdkKey}
              backendUrl2Access={config?.BLOCKCHAINID_BACKEND_BASE_URL}
              referralUrl2Access={config?.REFERRAL_URL}
              userAccessToken={localStorage?.getItem("user-access-token")}
              onUnauthorize={() => {
                refreshUserAccessTokenAPI?.executeQuery({
                  code: localStorage?.getItem(
                    "user-access-token-refresh-token"
                  ),
                });
              }}
            />
            <Sidebar
              sidebarIsOpen={menuOpen}
              setSidebarIsOpen={setMenuOpen}
              route={sidebarRoutes}
              logoutModalRef={logoutModalRef}
            />
            <div className={Style.content}>
              <div className={Style.navbarContent}>
                <Header
                  open={menuOpen}
                  setOpen={setMenuOpen}
                  logoutModalRef={logoutModalRef}
                />
              </div>
              <div className={Style.outlet} onClick={() => setMenuOpen(false)}>
                <Outlet
                  context={{
                    generalInfo,
                    generalModalRef,
                    generalErrorsResolver,
                  }}
                />
              </div>
            </div>
          </div>
        </NotificationContext.Provider>
      )}
    </PermissionRoute>
  );
};

export default MainLayout;
