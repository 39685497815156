import React, { useState, useContext, useEffect } from "react";

// Components
import Card from "../../../../components/advanced/card/Card";
import Separator from "../../../../components/advanced/separator/Separator";

// Libraries
import useQuery from "@hybris-software/use-query/dist/Hooks/useQuery";

// Data
import endpoints from "../../../../data/endpoints";

// Utilities
import { notificationTime } from "../../../../utils/utilityFunctions";

// Icons
import { PiArrowLeft } from "react-icons/pi";
import ActiveNotification from "../../../../assets/svgComponents/ActiveNotification";
import newsNotification from "../../../../assets/images/newsNotification.png";

// Style
import Style from "./Notification.module.css";
import { RoutesContext } from "../../../../contexts/RoutesContext";
import { Link } from "react-router-dom";
import { Button } from "@hybris-software/ui-kit";

const Notification = ({tag}) => {

  // States
  const [url, setUrl] = useState(null);
  const [broadcastHistory, setBroadcastHistory] = useState([]);

  

  // Queries
  const broadcastHistoryAPI = useQuery({
    url: url,
    method: "GET",
    executeImmediately: false,
    onSuccess: (response) => {
      setBroadcastHistory(response?.data?.results);
    },
  });

  useEffect(() => {
    if(tag) {
      setUrl(endpoints.notifications.GET_BROADCAST_HISTORY + `?limit=5&tab=${tag.name}`);
    } else {
      
      setUrl(endpoints.notifications.GET_BROADCAST_HISTORY + "?limit=5");
    }
  }, [tag]);
  useEffect(() => {
    if (url) broadcastHistoryAPI.executeQuery();
    // eslint-disable-next-line
  }, [url]);

  return (
    <Card
      className={Style.notificationBox}
      apiHook={broadcastHistoryAPI}
      minHeight={744}
    >
      {broadcastHistory.length <= 0 ? (
        <div className={Style.noData}>
          There are no notification for you right now
        </div>
      ) : (
        <>
          <div className={Style.notificationContainer}>
            {broadcastHistory?.map((item, index) => (
              <div key={index}>
                <NotificationItem item={item} delivered={true} />
                {index + 1 < broadcastHistory.length && (
                  <Separator
                    margin={"15px 0"}
                    className={!item?.read ? Style.notReadSep : ""}
                  />
                )}
              </div>
            ))}
          </div>
          <div className={Style.pagination}>
            <div
              style={{
                opacity:
                  broadcastHistoryAPI?.response?.data?.links?.previous === null
                    ? 0.5
                    : 1,
                cursor:
                  broadcastHistoryAPI?.response?.data?.links?.previous === null
                    ? "not-allowed"
                    : "pointer",
              }}
              className={Style.prev}
              onClick={() => {
                if (
                  broadcastHistoryAPI?.response?.data?.links?.previous !== null
                ) {
                  setUrl(broadcastHistoryAPI?.response?.data?.links?.previous);
                }
              }}
            >
              <PiArrowLeft />
            </div>

            <div
              style={{
                opacity:
                  broadcastHistoryAPI?.response?.data?.links?.next === null
                    ? 0.5
                    : 1,
                cursor:
                  broadcastHistoryAPI?.response?.data?.links?.next === null
                    ? "not-allowed"
                    : "pointer",
              }}
              className={Style.next}
              onClick={() => {
                if (broadcastHistoryAPI?.response?.data?.links?.next !== null) {
                  setUrl(broadcastHistoryAPI?.response?.data?.links?.next);
                }
              }}
            >
              <PiArrowLeft />
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

const NotificationItem = ({ item }) => {
  const { paths } = useContext(RoutesContext);

  return (
    <div className={Style.item} id={item?.id}>
      <div className={Style.activeNotification}>
        <div className={Style.activeNotificationPoint}>
          {!item.read && <ActiveNotification />}
        </div>
      </div>
      <div className={Style.type}>
        <div className={Style.icon}>
          <img
            as="image"
            rel="preload"
            src={newsNotification}
            alt=""
            width={35}
          />
        </div>
      </div>
      <div className={Style.text}>
        <div>
          <h5>{item.title}</h5>
          <div className={Style.time}>{notificationTime(item.createdAt)}</div>
          <div className={Style.shortDesc}>{item.shortDescription}</div>
        </div>
        <div className={Style.readMoreBtnWrapper}>
          <Link
            target="_blank"
            to={paths.main.notificationBody.replace(":id", item?.id)}
          >
            <Button className={Style.button}>Read More</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Notification;
