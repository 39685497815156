import React, { useEffect } from "react";

// Libraries
import useQuery from "@hybris-software/use-query";
import { useNavigate } from "react-router";

// Utilities
import { getUrlParams } from "../../utils/utilityFunctions";

// Data
import endpoints from "../../data/endpoints";
import { paths } from "../../data/routes";

const RedirectView = () => {
  const urlParams = getUrlParams();
  const navigate = useNavigate();

  const loginAPI = useQuery({
    url: endpoints.auth.LOGIN,
    method: "POST",
    executeImmediately: false,
    onSuccess: (response) => {
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user-access-token", response?.data?.userAccessToken);
      localStorage.setItem("user-access-token-refresh-token", response?.data?.userRefreshToken);
      navigate(paths.main.dashboard);
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        navigate(paths.main.dashboard);
      }
    },
  });

  const platformConfigAPI = useQuery({
    url: endpoints.PLATFORM_CONFIG,
    method: "GET",
    executeImmediately: true,
    onSuccess: (response) => {},
    clientOptions: {
      timeout: 120000,
    },
  });

  useEffect(() => {
    loginAPI.executeQuery({ code: urlParams.code });
    //eslint-disable-next-line
  }, []);

  return platformConfigAPI?.response?.data?.maintainanceMode ? (
    <RedirectToMaintenance />
  ) : (
    <></>
  );
};

export default RedirectView;

const RedirectToMaintenance = () => {
  // Consts
  const navigate = useNavigate();
  useEffect(() => {
    navigate(paths.generic.maintenance);
    //eslint-disable-next-line
  }, []);
  return <></>;
};
