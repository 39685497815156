import React, { useContext } from "react";

// Libraries
import { Link } from "react-router-dom";

// Context
import { RoutesContext } from "../../../contexts/RoutesContext";

// Styles
import Style from "./BroadcastBody.module.css";

const BroadcastBody = ({ broadcast }) => {
  const { paths } = useContext(RoutesContext);

  return (
    <Link
      to={paths.main.notificationBody.replace(":id", broadcast?.id)}
    >
      <div className={Style.BroadcastBody}>
        <h6>{broadcast?.title}</h6>
        <p className={Style.shortDesc}>{broadcast?.short_description}</p>
      </div>
    </Link>
  );
};

export default BroadcastBody;
