import React from "react";

const VipSupportIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: "scaleX(1)",
      }}
    >
      <path
        d="M16.5 16.8411H15.8033C15.07 16.8411 14.3733 17.1252 13.86 17.6386L12.2925 19.1878C11.5775 19.8936 10.4133 19.8936 9.69834 19.1878L8.13083 17.6386C7.6175 17.1252 6.91167 16.8411 6.1875 16.8411H5.5C3.97833 16.8411 2.75 15.6219 2.75 14.1186V4.11774C2.75 2.6144 3.97833 1.39526 5.5 1.39526H16.5C18.0217 1.39526 19.25 2.6144 19.25 4.11774V14.1186C19.25 15.6128 18.0217 16.8411 16.5 16.8411Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41699 7.94962C6.41699 7.09712 7.11366 6.40039 7.96616 6.40039C8.81866 6.40039 9.51533 7.09712 9.51533 7.94962C9.51533 9.67295 7.06782 9.85628 6.52699 11.4971C6.41699 11.8363 6.70116 12.1754 7.05866 12.1754H9.51533"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7036 12.1661V6.93194C14.7036 6.6936 14.5478 6.48272 14.3186 6.41855C14.0894 6.35438 13.8419 6.44605 13.7136 6.64772C13.0536 7.71105 12.3386 8.92106 11.7153 9.9844C11.6144 10.1586 11.6144 10.3877 11.7153 10.5619C11.8161 10.7361 12.0086 10.846 12.2194 10.846H15.5836"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VipSupportIcon;
