import { Route } from "react-router-dom";

// Layouts
import AuthLayout from "../layouts/authLayout/AuthLayout";
import MainLayout from "../layouts/mainLayout/MainLayout";

// Auth Views
import LoginView from "../views/auth/loginView";
import SpyLoginView from "../views/auth/spyLoginView";

// Main Views
import DashboardView from "../views/main/dashboardView";
import FirstStepView from "../views/main/firstStepView";
import CommunityView from "../views/main/communityView";
import ReferralView from "../views/main/referralView";
import BuilderReportView from "../views/main/builderReportView";
import RankTurnoverView from "../views/main/rankTurnoverView";
import RankOverview from "../views/main/rankOverview/RankOverview";
import ActiveQualifiedView from "../views/main/activeQualifiedView";
import ActiveQualifiedUnilevelView from "../views/main/activeQualifiedUnilevelView";
import DepthLevelUnlockedUniLevel from "../views/main/depthLevelUnlockedUniLevel/DepthLevelUnlockedUniLevel";
import ActiveQualifiedInfinityBonusView from "../views/main/activeQualifiedInfinityBonusView";
import WalletView from "../views/main/walletView";
import DirectBonusView from "../views/main/directBonusView";
import RedirectView from "../views/redirectView/RedirectView";
// import EventView from "../views/main/eventView";
// import EventDetailsView from "../views/main/eventDetailsView";
import ExportView from "../views/main/exportView";
import HistoryRewardsView from "../views/main/historyRewardsView";
import ActivitiesView from "../views/main/activitiesView";
import InfinityBonusView from "../views/main/infinityBonusView/InfinityBonusView";
import HouseBonusView from "../views/main/houseBonusView/HouseBonusView";
import UnilevelBonusView from "../views/main/unilevelBonusView/UnilevelBonusView";
import WikiView from "../views/main/wikiView/WikiView";
import LegalView from "../views/main/legalView/LegalView";
import Pools from "../views/main/pools";
import VipCustomerSupportView from "../views/main/vipCustomerSupportView/VipCustomerSupportView";
import NotificationView from "../views/main/notificationView/NotificationView";
import NotificationDetailsView from "../views/main/notificationDetailsView/NotificationDetailsView";
import DebtWalletsView from "../views/main/debtWalletsView";
import CommunityGrowthAnalyticsView from "../views/main/communityGrowthAnalyticsView/CommunityGrowthAnalyticsView";


// Full Screens
import TreeMemberFullScreenView from "../views/main/treeMemberFullScreenView";
import InfinityTreeFullScreenView from "../views/main/InfinityTreeFullScreenView";

// Generic
import MaintenanceView from "./../views/maintenanceView/MaintenanceView";

// Icons
import CommunityIcon from "../assets/svgComponents/CommunityIcon";
import ReferralIcon from "../assets/svgComponents/ReferralIcon";
import DashboardIcon from "../assets/svgComponents/DashboardIcon";
import RankOverviewIcon from "../assets/svgComponents/RankOverviewIcon";
import CommissionIcon from "../assets/svgComponents/CommissionIcon";
import EventIcon from "../assets/svgComponents/EventIcon";
import Activities from "../assets/svgComponents/Activities";
import ExportIcon from "../assets/svgComponents/ExportIcon";
import PoolIcon from "../assets/svgComponents/PoolIcon";
import WalletIcon from "../assets/svgComponents/WalletIcon";
import VipSupportIcon from "../assets/svgComponents/VipSupportIcon";
import { GoLaw } from "react-icons/go";
import { BiBookBookmark } from "react-icons/bi";
import Notifications from "../assets/svgComponents/Notifications";

const routes = {
  redirect: {
    path: "/",
    routes: {
      redirect: {
        path: "redirect",
        element: <RedirectView />,
        title: "Login",
        icon: null,
      },
    },
  },
  auth: {
    path: "/auth",
    element: <AuthLayout />,
    title: "Auth",
    routes: {
      login: {
        path: "/login",
        element: <LoginView />,
        title: "Login",
        icon: null,
      },
      inspect: {
        path: "/inspect",
        element: <SpyLoginView />,
        title: "Inspector",
        icon: null,
      },
    },
  },
  main: {
    path: "/",
    element: <MainLayout />,
    title: "Main",
    routes: {
      firstStep: {
        path: "first-step",
        element: <FirstStepView />,
        title: "First Step",
        icon: null,
      },
      dashboard: {
        path: "",
        element: <DashboardView />,
        title: "Dashboard",
        icon: <DashboardIcon />,
      },
      community: {
        path: "Community",
        element: <></>,
        title: "Community",
        icon: <CommunityIcon />,
        routes: {
          community: {
            path: "/community",
            element: <CommunityView />,
            title: "Community",
            icon: null,
          },
          growthAnalytics: {
            path: "/growth-analytics",
            element: <CommunityGrowthAnalyticsView />,
            title: "Growth Analytics",
            icon: null,
            showInSideBar: false,
          },
        },
      },
      growthAnalytics: {
        path: "growth-analytics",
        element: <CommunityGrowthAnalyticsView />,
        title: "Growth Analytics",
        icon: null,
        showInSideBar: false,
      },
      builderReport: {
        path: "builder-report/:id",
        element: <BuilderReportView />,
        title: "Builder Report",
        icon: null,
      },
      activities: {
        path: "activities",
        element: <ActivitiesView />,
        title: "Activities",
        icon: <Activities />,
      },
      referral: {
        path: "referral",
        element: <ReferralView />,
        title: "Referral",
        icon: <ReferralIcon />,
      },
      rankOverview: {
        path: "rank",
        element: <RankOverview />,
        title: "Rank",
        icon: <RankOverviewIcon />,
      },
      wallet: {
        path: "wallet",
        element: <WalletView />,
        title: "Commission Wallet",
        icon: <WalletIcon />,
      },
      debtWallets: {
        path: "debt-Wallets",
        element: <DebtWalletsView />,
        title: "Debt Wallets",
        showInSideBar: false,
      },
      commission: {
        path: "commission",
        element: <></>,
        title: "Commission",
        icon: <CommissionIcon />,
        routes: {
          qualified: {
            path: "/active-qualified",
            element: <ActiveQualifiedView />,
            title: "Qualified",
            icon: null,
          },
          directBonus: {
            path: "/direct-bonus",
            element: <DirectBonusView />,
            title: "Direct Bonus",
            icon: null,
          },
          unilevelBonus: {
            path: "/unilevel-bonus",
            element: <UnilevelBonusView />,
            title: "Unilevel Bonus",
            icon: null,
          },
          infinityBonus: {
            path: "/infinity-bonus",
            element: <InfinityBonusView />,
            title: "Infinity Bonus",
            icon: null,
          },
          historyRewards: {
            path: "/history-rewards",
            element: <HistoryRewardsView />,
            title: "History Rewards",
            icon: null,
          },
          houseBonus: {
            path: "/house-bonus",
            element: <HouseBonusView />,
            title: "House Bonus",
            icon: null,
          },
          bonus: {
            path: "/turnover-bonus",
            element: <RankTurnoverView />,
            title: "Turnover Bonus",
            icon: null,
          },
        },
      },
      qualifiedUnilevel: {
        path: "active-qualified-unilevel",
        element: <ActiveQualifiedUnilevelView />,
        title: "Qualified Unilevel",
        icon: null,
      },
      qualifiedInfinityBonus: {
        path: "active-qualified-infinity-bonus",
        element: <ActiveQualifiedInfinityBonusView />,
        title: "Qualified Infinity Bonus",
        icon: null,
      },
      pools: {
        path: "pools",
        element: <Pools />,
        title: "Pools",
        icon: <PoolIcon />,
        comingSoon: false,
      },
      depthLevelUnlockedUniLevel: {
        path: "depth-Level-Unlocked-UniLevel",
        element: <DepthLevelUnlockedUniLevel />,
        title: "",
        icon: null,
      },
      events: {
        path: "events",
        element: <></>,
        title: "Events",
        icon: <EventIcon />,
        comingSoon: true,
      },
      eventDetails: {
        path: "events/:id",
        element: <></>,
        title: "Event Details",
      },

      export: {
        path: "exports",
        element: <ExportView />,
        title: "Exports",
        icon: <ExportIcon />,
      },
      wiki: {
        path: "wiki",
        element: <WikiView />,
        title: "Wiki",
        icon: <BiBookBookmark />,
      },
      legal: {
        path: "legal",
        element: <LegalView />,
        title: "Legal",
        icon: <GoLaw />,
      },
      vipSupport: {
        path: "vip-customer-support",
        element: <VipCustomerSupportView />,
        title: " VIP Tools",
        icon: <VipSupportIcon />,
      },
      notifications: {
        path: "notifications",
        element: <NotificationView/>,
        title: "Notifications",
        icon: <Notifications />,
      },
      notificationBody: {
        path: "notifications/:id",
        element: <NotificationDetailsView/>,
        title: "Notification Body",
        icon: null,
      },
    },
  },
  fullScreen: {
    path: "/full-screen",
    title: "Insert title",
    routes: {
      memberTree: {
        path: "/member-tree",
        element: <TreeMemberFullScreenView />,
        title: "Member Tree",
        icon: null,
      },
      infinityTree: {
        path: "/infinity-tree",
        element: <InfinityTreeFullScreenView />,
        title: "Infinity Tree",
        icon: null,
      },
    },
  },
  generic: {
    path: "/generic",
    routes: {
      maintenance: {
        path: "/maintenance",
        element: <MaintenanceView />,
        title: "Login",
        icon: null,
      },
    },
  },
};

function generatePaths(routes, parentPath = "") {
  const paths = {};
  Object.entries(routes).forEach(([routeName, route]) => {
    if (route.routes) {
      paths[routeName] = generatePaths(route.routes, parentPath + route.path);
    } else {
      paths[routeName] = parentPath + route.path;
    }
  });
  return paths;
}

function generateRouteComponents(routes, parentPath = "") {
  if (parentPath === "") {
    return Object.keys(routes).map((routeName, index) => {
      return (
        <Route key={index} element={routes[routeName].element}>
          {generateRouteComponents(
            routes[routeName].routes,
            routes[routeName].path
          )}
        </Route>
      );
    });
  } else {
    const views = [];

    Object.keys(routes).forEach((routeName, index) => {
      if (!routes[routeName].comingSoon) {
        views.push(
          <Route
            key={index}
            path={parentPath + routes[routeName].path}
            element={routes[routeName].element}
          />
        );
      }

      if (routes[routeName].routes) {
        views.push(
          generateRouteComponents(
            routes[routeName].routes,
            parentPath + routes[routeName].path
          )
        );
      }
    });
    return views;
  }
}

const sidebarCategories = [
  {
    id: "menu",
    categoryTitle: "menu",
    showInSideBar: true,
    routes: [
      routes.main.routes.dashboard,
      routes.main.routes.community,
      routes.main.routes.referral,
      routes.main.routes.rankOverview,
      routes.main.routes.wallet,
      routes.main.routes.commission,
      routes.main.routes.pools,
    ],
  },
  {
    id: "others",
    categoryTitle: "others",
    showInSideBar: true,
    routes: [
      routes.main.routes.activities,
      // routes.main.routes.events,
      routes.main.routes.notifications,
      routes.main.routes.export,
      routes.main.routes.wiki,
      routes.main.routes.legal,
      routes.main.routes.vipSupport,
    ],
  },
];

function generatePathsWithCategoriesForSidebar(sidebarCategories) {
  const paths = {};
  sidebarCategories.forEach((category) => {
    paths[category.id] = {
      showInSideBar: category.showInSideBar,
      categoryTitle: category.categoryTitle,
      routes: category.routes,
    };
  });
  return paths;
}

const paths = generatePaths(routes);
const routeComponents = generateRouteComponents(routes);
const sidebarRoutes = generatePathsWithCategoriesForSidebar(sidebarCategories);
export { routes, paths, routeComponents, sidebarRoutes };
