import React from "react";

// Utilities
import classNames from "../../../utils/classNames";

// Styles
import Style from "./Separator.module.css";

const Separator = ({ isVertical = false, margin, className }) => {
  return (
    <div
      style={{ margin: margin }}
      className={classNames(
        isVertical ? Style.separatorVertical : Style.separatorHorizontal,
        className
      )}
    ></div>
  );
};

export default Separator;
