import React, { useEffect, useContext, useState } from "react";

// Components
import SimpleStyledCard from "../../../components/advanced/simpleStyledCard/SimpleStyledCard";
import Loader from "../../../components/ui/loader/Loader";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";
import Separator from "../../../components/advanced/separator/Separator";

// Libraries
import { PermissionRoute } from "@hybris-software/use-auth";
import {  useNavigate } from "react-router-dom";
import {  Container } from "@hybris-software/ui-kit";
import useQuery from "@hybris-software/use-query";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Utils
import classNames from "../../../utils/classNames";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Style
import Style from "./NotificationView.module.css";
import Notification from "./notification/Notification";

const taps = {
  All: 1983087447,
};
const NotificationView = () => {
  // Hooks
  const navigate = useNavigate();

  // States
  const [notificationTap, setNotificationTap] = useState(taps.All);

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Notifications`;
    //eslint-disable-next-line
  }, []);


  const getTagListAPI = useQuery({
    url: endpoints.notifications.GET_TAG_LIST,
    method: "GET",
    executeImmediately: true,
  });


  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <SimpleTitle title={"Notifications"} />

        <SimpleStyledCard>
          <>
            <div className={Style.tabsSection}>
              <div
                className={classNames(
                  Style.tapItem,
                  notificationTap === taps.All ? Style.tapItemActive : ""
                )}
                onClick={() => {
                  setNotificationTap(taps.All);
                }}
              >
                All
              </div>
              {getTagListAPI?.response?.data?.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    Style.tapItem,
                    notificationTap === item.id ? Style.tapItemActive : ""
                  )}
                  onClick={() => {
                    setNotificationTap(item.id);
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
            <Separator margin={"20px 0 40px"} />
              <Notification tag={getTagListAPI?.response?.data?.find((item)=> item.id === notificationTap)}/>
          </>
        </SimpleStyledCard>
      </Container>
    </PermissionRoute>
  );
};

export default NotificationView;
