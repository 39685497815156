import React, { useContext } from "react";

// Components
import Checkbox from "../../../components/advanced/checkbox/Checkbox";
import UnauthorizedModal from "../unauthorizedModal/UnauthorizedModal";
import StatusResponse from "../statusResponse/StatusResponse";
import ErrorModal from "../errorModal/ErrorModal";

// Libraries
import { useNavigate } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import useForm from "@hybris-software/use-ful-form";
import { Button } from "@hybris-software/ui-kit";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import endPoints from "../../../data/endpoints";

// Icons
import BillIcon from "../../../assets/icons/response-Icons/bell.png";

// Styles
import Style from "./LogoutModal.module.css";

const LogoutModal = ({ modalRef }) => {
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  const form = useForm({
    inputs: {
      logoutAll: {
        nature: "checkbox",
        value: false,
      },
    },
  });

  const logoutApi = useQuery({
    url: endPoints.auth.LOGOUT,
    method: "POST",
    onSuccess: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user-access-token");
      localStorage.removeItem("user-access-token-refresh-token");
      sessionStorage.clear();
      modalRef.current.destroy();
      navigate(paths.auth.login);
    },
    onError: (error) => {
      if (+error.response.status === 401) {
        modalRef.current.updateBody(<UnauthorizedModal />, {
          showCloseIcon: false,
        });
      } else if (+error.response.status === 403) {
        modalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title="Not Allowed"
            description={`You are not allowed to do this action.`}
          >
            <Button onClick={() => modalRef.current.destroy()}>Close</Button>
          </StatusResponse>
        );
      } else {
        modalRef.current.updateBody(
          <ErrorModal error={error} modalRef={modalRef} />
        );
      }
    },
    clientOptions: {
      timeout: 120000,
    },
  });

  const logoutAllApi = useQuery({
    url: endPoints.auth.LOG_OUT_ALL,
    method: "POST",
    onSuccess: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user-access-token");
      localStorage.removeItem("user-access-token-refresh-token");
      sessionStorage.clear();
      modalRef.current.destroy();
      navigate(paths.auth.login);
    },
    onError: (error) => {
      if (+error.response.status === 401) {
        modalRef.current.updateBody(<UnauthorizedModal />, {
          showCloseIcon: false,
        });
      } else if (+error.response.status === 403) {
        modalRef.current.updateBody(
          <StatusResponse
            icon={BillIcon}
            title="Not Allowed"
            description={`You are not allowed to do this action.`}
          >
            <Button onClick={() => modalRef.current.destroy()}>Close</Button>
          </StatusResponse>
        );
      } else {
        modalRef.current.updateBody(
          <ErrorModal error={error} modalRef={modalRef} />
        );
      }
    },
  });

  return (
    <div className={Style.logoutModal}>
      <h5 className={Style.title}>Are you sure you want to logout?</h5>
      <p className={Style.info}>
        You are about to log out of the platform, you can disconnect all devices
        if necessary.
      </p>

      <div className={Style.logoutAll}>
        <Checkbox
          checked={form.values["logoutAll"]}
          onChange={(e) =>
            form?.getInputProps("logoutAll").setValue(e.target.checked)
          }
        />
        Logout from all your devices
      </div>
      <Button
        className={Style.logoutButton}
        isLoading={logoutAllApi.isLoading || logoutApi.isLoading}
        onClick={() => {
          if (form.values["logoutAll"]) logoutAllApi.executeQuery();
          else logoutApi.executeQuery();
        }}
      >
        Logout
      </Button>
    </div>
  );
};

export default LogoutModal;
