import logoWhite from "../assets/logos/logo-white.svg";
import smallLogo from "../assets/logos/logo-small.svg";
import logoWords from "../assets/logos/logo-words.svg";
import LogoWhiteBid from "../assets/logos/bid-logo-icon.png";

let config = {
  REDIRECT_URL_LABEL: "redirect",
  WEBSITE_NAME: "XERA",
  WEBSITE_2ACCESS: "2access",
  WEBSITE_LFI: "KMALL",
  EURK_UNIT: "EurK",
  EURK_C_UNIT: "EurK - C",
  EURK_W_UNIT: "EurK - W",
  LOGO_WHITE: logoWhite,
  LOGO_SMALL: smallLogo,
  LOGO_WORDS: logoWords,
  LOGO_BID_WHITE: LogoWhiteBid,
  SCOPE: "openid platform_engagement",
  RESPONSE_TYPE: "code",
  MAX_FILE_SIZE: 1 * 1024 * 1024,
  WIKI_PAGE_URL: "https://wiki.xera.pro/",
  SUPPORT_LINK: "https://support.xera.pro/",
  TERMS_VERSION: 1,
  LEGAL_TERMS_URL: "https://docs.xera.pro/",
};

if (process.env.NODE_ENV === "development") {
  config = {
    ...config,
    API_BASE_URL: "https://dev.back.pipeline.tbe.io/api/v1/",
    // API_BASE_URL: "http://10.101.12.223:8001/api/v1/",
    BLOCKCHAINID_BASE_URL: "http://localhost:3001",
    BLOCKCHAINID_BACKEND_BASE_URL: "https://dev.back.pipeline.myblockchainid.io/api/v1/",
    LFI_BASE_URL: "http://localhost:3001/",
    DEBUG_MODE: true,
    // WEBSOCKET_NOTIFICATION_URL: "ws://10.101.12.223:8001/ws/v1/notifications/",
    WEBSOCKET_NOTIFICATION_URL: "wss://dev.back.pipeline.tbe.io/ws/v1/notifications/",
    CAPTCHA_SITE_KEY: "10000000-ffff-ffff-ffff-000000000001",
    CLIENT_ID: "WnEJv2NJnbcaTjUy3yjNC0yocgVBtkIVCCDZERPC",
    REDIRECT_URI: "http://localhost:3000/redirect",
    REFERRAL_URL: "https://staging-bid.lfi.io/auth/register?refcode=",
  };
} else {
  const requiredEnvVars = [
    "REACT_APP_API_BASE_URL",
    "REACT_APP_BLOCKCHAINID_BASE_URL",
    "REACT_APP_BLOCKCHAINID_BACKEND_BASE_URL",
    "REACT_APP_LFI_BASE_URL",
    "REACT_APP_WEBSOCKET_NOTIFICATION_URL",
    "REACT_APP_CAPTCHA_SITE_KEY",
    "REACT_APP_DEBUG_MODE",
    "REACT_APP_CLIENT_ID",
    "REACT_APP_REDIRECT_URI",
    "REACT_APP_REFERRAL_URL",
  ];

  requiredEnvVars.forEach((envVar) => {
    if (!process.env[envVar]) {
      throw new Error(`Missing required environment variable ${envVar}`);
    }
  });

  config = {
    ...config,
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    BLOCKCHAINID_BASE_URL: process.env.REACT_APP_BLOCKCHAINID_BASE_URL,
    BLOCKCHAINID_BACKEND_BASE_URL: process.env.REACT_APP_BLOCKCHAINID_BACKEND_BASE_URL,
    LFI_BASE_URL: process.env.REACT_APP_LFI_BASE_URL,
    DEBUG_MODE: ["true", "1"].includes(
      process.env.REACT_APP_DEBUG_MODE.toLowerCase()
    ),
    WEBSOCKET_NOTIFICATION_URL:
      process.env.REACT_APP_WEBSOCKET_NOTIFICATION_URL,
    CAPTCHA_SITE_KEY: process.env.REACT_APP_CAPTCHA_SITE_KEY,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
    REFERRAL_URL: process.env.REACT_APP_REFERRAL_URL,
  };
}

export default config;
