import React, { useEffect, useContext } from "react";

// Components
import Loader from "../../../components/ui/loader/Loader";
import Card from "../../../components/advanced/card/Card";

// Libraries
import { Container } from "@hybris-software/ui-kit";
import { PermissionRoute } from "@hybris-software/use-auth";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "@hybris-software/use-query";
import { useWindowSize } from "@hybris-software/ui-kit";

// Contexts
import { RoutesContext } from "../../../contexts/RoutesContext";

// Data
import config from "../../../data/config";
import endpoints from "../../../data/endpoints";

// Styles
import Style from "./NotificationDetailsView.module.css";
import SimpleTitle from "../../../components/advanced/simpleTitle/SimpleTitle";

const NotificationDetailsView = () => {
  // Consts
  const { id } = useParams();
  const { width } = useWindowSize();
  // Hooks
  const navigate = useNavigate();

  // Contexts
  const { paths } = useContext(RoutesContext);

  useEffect(() => {
    document.title = `${config.WEBSITE_NAME} | Notification Body`;
    //eslint-disable-next-line
  }, []);

  //Queries
  const getNotificationDetailsApi = useQuery({
    url: endpoints.notifications.READ_BROADCAST_NOTIFICATION(id),
    method: "POST",
    executeImmediately: true,
  });

  return (
    <PermissionRoute
      loader={<Loader />}
      forLoggedUser={true}
      unAuthorizedAction={() => {
        navigate(paths.auth.login);
      }}
      permissionController={(response) => {
        // dispatcher
        return true;
      }}
    >
      <Container>
        <SimpleTitle title={"Notification"} />

        <Card apiHook={getNotificationDetailsApi}>
          <h4>{getNotificationDetailsApi?.response?.data?.shortDescription}</h4>
          <div
            className={Style.notificationImage}
            style={{
              backgroundImage: `url(${
                width > 576
                  ? getNotificationDetailsApi?.response?.data?.image
                  : getNotificationDetailsApi?.response?.data?.mobileImage
              })`,
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html: getNotificationDetailsApi?.response?.data?.description,
            }}
          />
        </Card>
      </Container>
    </PermissionRoute>
  );
};

export default NotificationDetailsView;
