import React from "react";

// Libraries
import { Button } from "@hybris-software/ui-kit";

// Styles
import Style from "./Notification.module.css";

const Notification = ({ id, message, onClose }) => {
  return (
    <div className={Style.notification}>
      {message}
      <Button onClick={() => onClose(id)} style={{ marginLeft: "10px" }}>
        Close
      </Button>
    </div>
  );
};

export default Notification;
